import React from "react"
import styled from "styled-components"
import { faAt, faMapMarkedAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import Cta from './cta'

const FooterWrapper = styled.footer`
  background-color: #1769aa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  padding: 1rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`
const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
    ul {
        margin: 0;
        list-style: none;
        padding: 0;

        li {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }
`
const AddressLine = styled.p`
    margin-bottom: .5rem;
`

const openingTime = '08:00 - 17:00';

const Footer = () => (
    <FooterWrapper>
        <FooterItem>
            <Cta
                href="tel:+44 1536 712398"
                icon={faPhoneAlt}
                text="Call Us"
            />
            <p>01536 712398</p>
            <Cta
                href="mailto:tyetune@yahoo.co.uk"
                icon={faAt}
                target="_blank"
                text="Email Us"
            />
            <p>tyetune@yahoo.co.uk</p>
        </FooterItem>
        <FooterItem>
            <Cta
                href="https://goo.gl/maps/St9ihjzVsVC52ynh9"
                icon={faMapMarkedAlt}
                target="_blank"
                text="Find Us"
            />
            <AddressLine>Station Yard,</AddressLine>
            <AddressLine>Pullman Close,</AddressLine>
            <AddressLine>Rushton,</AddressLine>
            <AddressLine>Kettering NN14 1RJ</AddressLine>
        </FooterItem>
        <FooterItem>
            <p>Opening Times:</p>
            <ul>
                <li>Monday: <span>{openingTime}</span></li>
                <li>Tuesday: <span>{openingTime}</span></li>
                <li>Wednesday: <span>{openingTime}</span></li>
                <li>Thursday: <span>{openingTime}</span></li>
                <li>Friday: <span>{openingTime}</span></li>
                <li>Saturday: <span>Closed</span></li>
                <li>Sunday: <span>Closed</span></li>
            </ul>
        </FooterItem>
    </FooterWrapper>
)

export default Footer
