import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Cta = styled.a`
    align-items: center;
    background-color: #4dabf5;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    color: #fff;
    display: flex;
    margin-bottom: 1rem;
    padding: .5rem;
    text-decoration: none;
    width: auto;

    :hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 3px -4px, rgba(0, 0, 0, 0.14) 0px 4px 4px 2px, rgba(0, 0, 0, 0.12) 0px 3px 7px 2px;
    }
    svg {
        margin-right: .5rem;
    }
`

const Footer = ({href, icon, target, text}) => {
    return (
        <Cta
            href={href}
            target={target}
            rel="noreferrer"
            >
            <FontAwesomeIcon icon={icon} />
            {text}
        </Cta>
    );
}

export default Footer
