import React from "react"
import styled from "styled-components"

const InfobarWrapper = styled.div`
    display: none;
    a {
        color: #444;
        margin-bottom: .5rem;
        text-decoration: none;
    }

    @media screen and (min-width: 960px) {
        align-items: center;
        display: flex;
        justify-content: space-between;

        a {
            margin: .5rem;
        }
    }
`

const Infobar = () => (
  <InfobarWrapper>
    <a href="tel:+44 1536 712398">01536 712398</a>
    <a href="https://goo.gl/maps/St9ihjzVsVC52ynh9" rel="noreferrer" target="_blank">Station Yard, Pullman Close, Rushton, Kettering NN14 1RJ</a>
    <a href="mailto:tyetune@yahoo.co.uk"  rel="noreferrer" target="_blank">tyetune@yahoo.co.uk</a>
  </InfobarWrapper>
)

export default Infobar
