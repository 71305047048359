import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Infobar from './infobar'

const HeaderWrapper = styled.header`
  background-color: #1769aa;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  padding: 1rem;
`
const TitleWrapper = styled.div`
  margin:0 auto;
  max-width: 960px;
  text-align: center;
  h1 {
    margin: 0;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  @media screen and (min-width: 480px) {
    padding: 1.5rem;
  }
  @media screen and (min-width: 560px) {
    padding: 1.5rem;
    text-align: left;
  }
  @media screen and (min-width: 960px) {
    padding: 3rem;
  }
`

const Header = ({ siteTitle }) => (
  <>
    <Infobar />
    <HeaderWrapper>
      <TitleWrapper>
        <h1>
          <Link to="/">
            {siteTitle}
          </Link>
        </h1>
      </TitleWrapper>
    </HeaderWrapper>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
